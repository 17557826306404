const assetBaseUrl = import.meta.env.VITE_STATIC_ASSET_BASE_URL;

export const articles = [
  {
    id: "1",
    title:
      "Myths about electric motors that create doubt, Here's the explanation.",
    label: "Article",
    slug: "Mitos-tentang-Motor-Listrik-yang-bikin-ragu",
    image: `${assetBaseUrl}/image/contents/mitos.jpg`,
    description: "Deskripsi untuk artikel mitos",
    content: `
      <p class="text-xl font-medium mb-5">
        You must have often heard various conflicting information about electric motors. Hey, don't believe immediately in information from unclear sources. Here are the myths circulating that make people hesitant to switch to electric motors, along with the actual facts. Here are 4 myths prevalent in society along with the real facts:
      </p>
      <h5 class="text-md font-bold my-4">1. Not Environmentally Friendly</h5>
      <p><b>Myth:</b> Electric vehicles are not environmentally friendly because producing batteries contributes to environmental pollution.</p>
      <p><b>Fact:</b> Electric vehicles have an overall lower carbon footprint compared to fossil fuel-powered vehicles. As technology advances, battery production processes are becoming more environmentally friendly. Moreover, more energy used to charge electric vehicle batteries is sourced from renewable energy.</p>

      <h5 class="text-md font-bold my-4">2. Limited Range</h5>
      <p><b>Myth:</b> JElectric vehicles have a relatively short driving range compared to fossil fuel-powered vehicles.</p>
      <p><b>Fact:</b> Modern electric vehicles can accommodate the daily needs of most people because they can achieve high driving ranges. In some of the latest electric car models, ranges can even reach from 200 to 652 km, while electric motorcycles can cover over 100 km per charge, such as the VISION.ev which can go up to 125 km per charge, making it suitable for daily use. The development of charging infrastructure also makes long-distance travel easier.</p>

      <h5 class="text-md font-bold my-4">3. Difficult to Charge? </h5>
      <p><b>Myth:</b> Charging infrastructure for batteries is inadequate.</p>
      <p><b>Fact:</b> While there are currently fewer charging stations compared to traditional fuel stations (gas stations), the infrastructure for electric vehicle charging is rapidly expanding. Many major cities and main highways now have fast charging stations available. Additionally, many homes and workplaces are beginning to install private charging stations, supported by collaborations between government and private sectors to increase the availability of EV charging stations in Indonesia.</p>

      <h5 class="text-md font-bold my-4">3. Long Charging Times </h5>
      <p><b>Myth:</b> Charging batteries takes a long time and is inefficient.</p>
      <p><b>Fact:</b> The charging time for electric vehicles varies depending on the type of charger used. For instance, the latest electric motorcycles like those from iMoto Indonesia equipped with Fast Chargers can charge up to 80% in just 30 minutes. Ultra Fast Chargers can achieve this in as little as 15 minutes.</p><br>

      <p>With the facts about the myths mentioned above, it should alleviate doubts about using electric vehicles.<br>
Follow iMoto Indonesia on social media for more information about electric motorcycles.</p>

 
    `,
    related: ["2", "3", "4", "5", "6", "7", "8", "9"],
  },
  {
    id: "2",
    title: "Benefits of Owning an Electric Motorcycle in Today's Era",
    label: "Article",
    slug: "keuntungan-punya-motor-listrik",
    image: `${assetBaseUrl}/image/contents/keuntungan.jpg`,
    description: "deskripsi pada konten keuntungan punya motor listrik",
    content: `
    <p>Owning an electric motorcycle in the modern era offers numerous advantages. With increasing awareness of environmental issues among the public, automotive manufacturers are racing to create technological innovations that are not only advanced but also environmentally friendly. A prime example is IMOTO Indonesia, which has successfully developed the innovative VISION.ev electric motorcycle, designed by local talent.
    <br><br>Electric motorcycles have become revolutionary heroes in the modern transportation world, experiencing significant development since their inception. The Chairman of the Indonesian Electric Motorcycle Industry Association (AISMOLI), Budi Setiyadi, noted that the population of electric motorcycles in Indonesia has now reached 100,000 units, reflecting the rapid adoption of this eco-friendly technology.
    <br><br>The Indonesian government is giving a substantial boost to the electric motorcycle industry by offering a subsidy of Rp 7 million per unit through the Ministry of Industry. This subsidy is part of an effort to make electric vehicles more affordable for the public. According to Luhut Binsar Pandjaitan, the Coordinating Minister for Maritime Affairs and Investment, this initiative aims to stimulate the growth of the electric motorcycle and car industry in Indonesia. With an ambitious target of producing 1.2 million electric motorcycles and 75,000 electric cars by 2024, the government is following the lead of other countries that have implemented similar incentives.

    <br><br>Meanwhile, electric motorcycles are advancing rapidly with integrated Internet of Things (IoT) technology, as introduced by IMOTO Indonesia. The IMOTO.ev app, available for download on the Apple App Store and Google Playstore, not only allows riders to control their vehicles remotely via smartphone but also offers cutting-edge features that enhance the riding experience beyond what other brands' apps offer.</p>
    
    <p class="text-xl font-medium mt-10">
        So, what are the additional advantages that make switching from fossil-fueled motorcycles to electric motorcycles a compelling choice? These benefits are tangible in everyday life, and we have summarized them below:
      </p>
      <br>

      <div class="flex">
      <p class="mr-3 font-medium">1.</p>
      <p>Zero Emissions: Electric vehicles do not produce exhaust emissions that cause air pollution.</p>
      </div>
      <div class="flex">
      <p class="mr-3 font-medium">2.</p>
      <p>Energy Efficiency: Electric motorcycles address the challenge of oil scarcity, offering significant fuel savings.
</p>
      </div>
      <div class="flex">
      <p class="mr-3 font-medium">3.</p>
      <p>Cost-Effective Maintenance: Maintenance costs for electric motorcycles are much lower, focusing only on the battery and motor, keeping your wallet safe.</p>
      </div>
      <div class="flex">
      <p class="mr-3 font-medium">4.</p>
      <p>Superior Acceleration: With a lighter weight, electric motorcycles offer more agile acceleration, ready to ride without hindrance.
</p>
      </div>
      <div class="flex">
      <p class="mr-3 font-medium">5.</p>
      <p>Comfort and Quietness: Electric motorcycles operate without the noisy sounds of a dynamo or rotor, providing a more comfortable and quiet ride.
</p>
      </div>
      <div class="flex">
      <p class="mr-3 font-medium">6.</p>
      <p>Convenient Charging: Electric motorcycles can be charged at home, typically requiring only two charging sessions per week, making them practical and efficient.</p>
      </div>

      <br><br>Electric motorcycles are not only an eco-friendly solution in this modern era but also offer a host of significant practical advantages, making them an increasingly relevant choice for daily mobility.

      <br><br>Stay updated with the latest information and interesting tips about electric motorcycles by following IMOTO Indonesia on social media. Don’t miss the chance to be part of the electric vehicle revolution in Indonesia!
</p>
`,
    related: ["1", "3", "4", "5", "6", "7", "8", "9"],
  },
  {
    id: "3",
    title: "Can Electric Motorcycles Explode? Follow These Steps to Stay Safe",
    label: "Article",
    slug: "motor-listrik-bisa-meledak?",
    image: `${assetBaseUrl}/image/contents/meledak.jpg`,
    description: "Deskripsi untuk artikel meledak",
    content: `<p>With the growing popularity of electric motorcycles among modern consumers, there has been an increase in fire incidents involving these vehicles. A common question is whether an electric motorcycle can catch fire. The answer is yes; electric motorcycles can experience fires. This can happen due to several factors, including errors during modifications where incompatible parts are used or improper reassembly after modifications, leading to unstable voltage during charging. Another major cause is overheating (excessive battery temperature).</p>
    <br><br><p>To minimize these risks, electric motorcycle users are advised to conduct regular checkups every 2–3 months to inspect the battery and wiring. Key areas to examine include possible leaks in battery terminals and any cables that might be pinched or damaged. Here are some tips to help you safely enjoy your electric motorcycle:
</p>
<br>
<div class="flex">
      <p class="mr-3 font-medium">1.</p>
      <p>Avoid Completely Depleting the Battery: Try to charge your motorcycle as soon as you get home if the battery is at or below 20% instead of letting it fully drain.
</p>
      </div>
<div class="flex">
      <p class="mr-3 font-medium">2.</p>
      <p>Prevent Overcharging: Avoid charging beyond full capacity. Typically, charging for a maximum of 4 hours is enough. If it reaches full charge before that, unplug it. Avoid charging overnight.</p>
      </div>
<div class="flex">
      <p class="mr-3 font-medium">3.</p>
      <p>Take a Break if Charging Takes Too Long: If your battery isn’t full after 4 hours of charging, disconnect it, let it rest for 20–30 minutes, then resume charging.</p>
      </div>
<div class="flex">
      <p class="mr-3 font-medium">4.</p>
      <p>Charge Outdoors and Away from Direct Sunlight: Always charge in an outdoor area away from direct sunlight.</p>
      </div>
<div class="flex">
      <p class="mr-3 font-medium">5.</p>
      <p>Cool Down Before Charging: If you plan to charge right after riding, let the motorcycle cool for 15 minutes beforehand. Avoid fully depleting the battery and try to charge it when you get home at around 20%.
</p>
      </div>
<div class="flex">
      <p class="mr-3 font-medium">6.</p>
      <p>Replace Problematic Batteries: If one of your motorcycle’s batteries has issues, replace it promptly to avoid damage to the others over time.</p>
      </div>

      <br><br><p>These six tips are helpful for electric motorcycle owners as they offer straightforward maintenance steps, reducing the risk of incidents. Follow these guidelines to stay safe and avoid concerns about electric motorcycle fires.</p>
`,
    related: ["1", "2", "4", "5", "6", "7", "8", "9"],
  },
  {
    id: "4",
    title:
      "How to Keep Your Electric Motorcycle Battery in Prime Condition: Tips from IMoto Indonesia",
    label: "Article",
    slug: "cara-menjaga-performa-baterai-motor-listrik",
    image:
      "https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/hemat.jpg",
    content: `
    <p>We all agree that the operational costs of an electric motorcycle are far cheaper than a gasoline-powered one. The battery-powered motor significantly lowers operating expenses compared to gasoline, with higher efficiency translating into more mileage per kWh than per liter of fuel.</p>
 <br><br><p>However, a common question that arises among prospective users is: "Can an electric motorcycle battery last a long time?" To address that concern, here are some tips from IMoto Indonesia to help keep your electric motorcycle battery durable, even with frequent use.</p>
<br>
<p class="mr-3 font-semibold">1. Charge Your Electric Motorcycle Battery Regularly and Consistently, Don’t Wait Until 0%! </p>   
<p>Charge your electric motorcycle battery at least every two or three days to maintain performance, even if you don’t use it often. For frequent riders, aim to charge when the battery reaches 20% to ensure optimal performance. Draining it to 0% regularly can damage the components and disrupt battery efficiency.</p>
</div>
<br>
 <p class="mr-3 font-semibold">2. Avoid Charging When the Battery is Hot 
</p>
<p>Wait until the battery has cooled down before charging to prevent component damage. Charging when the motor is still hot can lead to overheating, which in extreme cases, could trigger a fire or explosion.
<br>
<br>
<p class="mr-3 font-semibold">3. Park in a Shaded Area, Avoid Direct Sunlight</p>
<p>Direct sunlight can overheat the lithium battery in electric motorcycles, reducing its lifespan. Always aim to park in shaded areas to avoid prolonged sun exposure.
<br>
<br>
<p class="mr-3 font-semibold">4. Use the Original Charger 
</p>
<p> Use only the charger recommended by the manufacturer, as an incompatible charger may cause overheating and damage battery components.</p>
<br>
<p class="mr-3 font-semibold">5. Keep the Battery and Connectors Clean</p>
<p>Ensure the battery and connectors are clean and free of dirt or corrosion. Dirt and corrosion can reduce charging efficiency and battery power output.</p>
<br>
<p class="mr-3 font-semibold">6. Avoid Harsh Impacts</p>
<p>Hard impacts can loosen attached components and affect battery performance. In some cases, a strong impact can cause damage or even result in a fire if the battery is compromised.</p>
<br>
<p class="mr-3 font-semibold">7. Avoid Overloading the Motorcycle
</p>
<p>Carrying loads beyond the motorcycle's capacity can strain the battery and other components, decreasing efficiency and accelerating wear.</p>
<br>
<p>These are a few tips from IMoto Indonesia to keep your electric motorcycle battery in peak condition. Ready to experience the benefits of an IMoto electric motorcycle? Don’t forget to follow us on Instagram @imoto_indonesia for more updates and information!</p>
<br>
`,
    related: ["1", "2", "3", "5", "6", "7", "8", "9"],
  },
  {
    id: "5",
    title:
      "IMOTO Global Debut VISION.ev: Indonesian Electric Motorcycle for the World with 30-Minute Fast Charging Advantage",
    label: "Event",
    slug: "imoto-vision-motor-listrik-buatan-indonesia?",
    image: `${assetBaseUrl}/image/contents/debut-vision.jpg`,
    description:
      "Dirancang dan Dikembangkan Sepenuhnya di Indonesia dengan Target TKDN 75%, VISION.ev adalah Tech Gadget Terbaru dalam Industri Sepeda Motor Listrik",
    content: `<div class="font-bold mb-5"><p>Fully Designed and Developed in Indonesia with a 75% Local Content Target, VISION.ev is the Latest Tech Innovation in the Electric Motorcycle Industry</p></div>
    
<br><p><b>Jakarta, July 16, 2024</b> – IMOTO proudly introduced its latest innovation, VISION.ev, at its Global Debut event held at the Sheraton Hotel Gandaria in Jakarta on July 16, 2024. VISION.ev will be the first electric motorcycle in Indonesia with a Domestic Content Level (TKDN) target exceeding 75%, set to launch in 2025.</p>
<br><p>“We are extremely proud of the launch of the VISION.ev concept. We hope that VISION.ev will drive the adoption of electric vehicles in Indonesia and strengthen Indonesia's position in the global market. With the tagline 'Made in Indonesia, for the World,' IMOTO aims to showcase local talent to international markets. From design to battery production, it is all locally made, helping us achieve our 75% TKDN target,” explained IMOTO Indonesia Co-Founder Antony Lesmana in an official statement on Tuesday (July 16, 2024).</p>
<br><p>VISION.ev is unique, being fully designed and developed in Indonesia by young Indonesians in just 2.5 years. The motorcycle features multiple innovative technologies, including fast-charging capabilities that enable the battery to charge from 20% to 80% in only 30 minutes. Equipped with a 5.7 kWh battery, it boasts a range of up to 160 km per charge, a maximum speed of 105 km/h, advanced digital features like a touchscreen, infotainment system, keyless entry, tracking application, geofencing, fleet management, and a futuristic, ergonomic design that suits Indonesian market preferences.</p>
<br><p>“With the advantages offered by VISION.ev, we are confident it will be an efficient and environmentally-friendly transportation solution. VISION.ev addresses key consumer concerns about EVs, such as fast charging, battery life, range per charge, and vehicle performance, which will accelerate EV adoption,” said IMOTO Indonesia Co-Founder Doddy Lukito.</p>
<br><p>Doddy also highlighted that to ensure user convenience, IMOTO is developing an extensive fast-charging ecosystem. The public charging network will be available at various strategic points across urban and intercity areas, with an initial deployment of 200 charging stations planned.</p>
<div class="space-y-3">
<br><br><p class="font-bold">IMOTO’s Commitment</p>
<p>Through its superior electric motorcycle products, IMOTO offers not only advanced technology but also promotes a sustainable lifestyle. With the future of Indonesia's automotive industry leaning toward electric vehicles, IMOTO will continue innovating to create products and services that make carbon-free mobility accessible at very low adoption costs.</p>
<p>“We believe that IMOTO will become a Zero Carbon company by 2035 by continuously developing fast-charging battery technology and environmentally friendly products. Our commitment is to provide clean, green, economical, and efficient mobility for Indonesians,” said Antony.</p>
<p>In addition, IMOTO is committed to using local materials, supporting the domestic industry, creating new jobs, and developing local talent to build a global product in the EV industry.</p>
</div>
<br><p class="text-center">***</p>
<img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/debut-vision2.jpg" alt="Debut Vision 2">
<br><p>About IMOTO Indonesia:Founded in 2021, IMOTO is a technology company led by automotive and energy professionals committed to Empowering Human Mobility through Advanced Electric Technology for a Sustainable World. IMOTO believes that the future of Indonesia's automotive industry lies in electric vehicles (EV) and is building electric motorcycles FOR Indonesia, IN Indonesia, BY Indonesians. IMOTO aims to achieve self-reliance in EV powertrain technology by developing fast-charging batteries, efficient controllers, and other EV components.</p>

  `,
    related: ["1", "2", "3", "4", "6", "7", "8", "9"],
  },
  {
    id: "6",
    title:
      "Why do Gen Z and Millennials choose electric motorbikes? this is the reason",
    label: "Article",
    slug: "kenapa-gen-z-dan-milenial-pilih-motor-listrik?",
    image: `${assetBaseUrl}/image/contents/gen-z.jpg`,
    description:
      "Kenapa Gen Z dan Milenial secara masal beralih ke motor listrik seperti octa gt sebagai solusi ramah lingkungan untuk mengurangi polusi udara.",
    content: `<div class="font-bold mb-5"><p>Why are Gen Z and Millennials turning en masse to electric motorbikes like the Octa GT as an environmentally friendly solution to reducing air pollution.</p></div>
<br><p>Millennials (born 1983-1994) and Gen Z (born 1995-2004) are known for their enthusiasm, innovation, and adaptability to change. Growing up in the digital age has allowed them to continuously evolve with technology while embracing their passions and leading sustainable lifestyles. Unsurprisingly, they are also active in social and environmental issues. Amidst climate change and increasing environmental awareness, both Generations Z and Millennials are showing a growing interest in electric vehicles (EVs) as a concrete step to minimize the impact of climate change and air pollution, particularly through their interest in electric motorcycles. 
</p>
<br><p>According to global research and Deloitte surveys, there are several key reasons behind this interest: economic factors, ease of use, and environmental concern. The strong preference for purchasing electric vehicles has now become a trend among them. Deloitte's research and survey results indicate that both Gen Z and Millennials are very concerned about climate change. About 69% of Gen Z and 73% of Millennials are committed to reducing their environmental impact by purchasing electric vehicles. The data also reveals that 44% of Millennials and 44% of Gen Z plan to buy electric vehicles, with 15% of Millennials and 16% of Gen Z already owning them, actively working to minimize the impact of climate change through their purchase of electric vehicles, especially electric motorcycles.</p>
<img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/1.webp" alt="data statistik">
<p class="text-xs">Source: <a href="https://www.deloitte.com/content/dam/assets-shared/docs/campaigns/2024/deloitte-2024-genz-millennial-survey.pdf?dlva=1">https://www.deloitte.com/content/dam/assets-shared/docs/campaigns/2024/deloitte-2024-genz-millennial-survey.pdf?dlva=1</a> </p>
<br><p>Young adults who are beginning to achieve financial independence are increasingly opting for electric motorcycles as a smart mobility solution. With their more affordable price, easy maintenance, and unmatched fuel efficiency, electric motorcycles have become a favorite choice. This trend is further supported by findings in the "Indonesia Electric Vehicle Consumer Survey 2023" released by PwC in September 2023.
 </p>
<br><img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/2.webp" alt="data statistik">
<p class="text-xs">Suource: <a href="https://www.pwc.com/id/en/publications/automotive/indonesia-electric-vehicle-consumer-survey-2023.pdf">https://www.pwc.com/id/en/publications/automotive/indonesia-electric-vehicle-consumer-survey-2023.pdf</a> </p>
<br><p>Decision to switch to electric vehicles is not straightforward, as it is influenced by numerous factors ranging from environmental concerns to economic considerations. Understanding consumer perspectives on electric vehicles is crucial for the industry and stakeholders in developing effective marketing strategies.
</p>
<br><p>Data reveals an intriguing contrast between car and motorcycle ownership. While 19% of respondents still use fossil-fuel cars, only 5% have transitioned to hybrid or electric vehicles. In contrast, 85% of respondents continue to rely on fossil-fuel motorcycles, with 17% having adopted hybrid or electric technology. These figures highlight both the opportunities and challenges facing the electric vehicle industry moving forward.
</p>
<br><img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/3.webp" alt="data statistik">
<p class="text-xs">Source: <a href="https://www.pwc.com/id/en/publications/automotive/indonesia-electric-vehicle-consumer-survey-2023.pdf">https://www.pwc.com/id/en/publications/automotive/indonesia-electric-vehicle-consumer-survey-2023.pdf</a> </p>
<br><p>Research shows that Millennials have a strong enthusiasm and interest in using electric vehicles, thanks to information from social media and higher levels of education. Around 73% of young consumers in Indonesia want to own an electric motorcycle, although they are still concerned about the limited number of battery charging stations.
</p>
<br><p>In Indonesia, more and more electric motorcycle brands are available, with prices ranging from IDR 14.5 million to IDR 70 million depending on the brand, specifications, and other factors. Therefore, IMOTO Indonesia introduces OCTA as a reference for Millennials and Gen Z, known for being energetic, innovative, and adaptable.</p>
<br><img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/4.webp" alt="data statistik">
<p class="text-xs">Source: <a href="https://www.pwc.com/id/en/publications/automotive/indonesia-electric-vehicle-consumer-survey-2023.pdf">https://www.pwc.com/id/en/publications/automotive/indonesia-electric-vehicle-consumer-survey-2023.pdf</a> </p>
<br><p>Not only does OCTA significantly contribute to reducing pollution, but it also offers a touch of personal uniqueness for each rider. With a diverse range of color options, users can customize their motorcycle to reflect their personality and lifestyle, making it not just an eco-friendly transportation solution, but also a fashion statement and personal identity. For Generation Z and Millennials, who are known for using color to express themselves and their stance on social and environmental issues, OCTA is the ideal solution. With all its advantages, it is poised to be the top choice for those looking to combine environmental consciousness with a stylish and sophisticated lifestyle.
</p>

  `,
    related: ["1", "2", "3", "4", "5", "7", "8", "9"],
  },
  {
    id: "7",
    title:
      "IMOTO Rapid Battery: Revolutionizing Electric Motorcycle Batteries with Super-Fast Charging Experience",
    label: "Event",
    slug: "imoto-rapid-battery-revolusi-cas-motor-listrik-super-cepat",
    image: `${assetBaseUrl}/image/contents/content7.webp`,
    description:
      "IMOTO Rapid Battery, teknologi pengisian baterai  motor listrik super cepat hanya butuh 30 menit untuk mengisi daya dari 20% hingga 80% buatan lokal",
    content: `<div class="font-bold mb-5"><p>As electric motorcycles gain popularity among the public, more people are switching from fossil fuel-powered vehicles. The reasons are clear: significantly lower operational costs, environmental friendliness, and numerous other benefits offered by electric motorcycles. However, one challenge that users still face is the Life of the battery and distance covered in single charge and they feel the swap system is considered a practical solution for the mentioned concerns in electric motorcycles. In reality, this system is not the ideal solution and also still difficult to implement. But why?
</p></div>
<br><p>Although the battery swap system may initially appear practical, it faces a significant challenge: the disparity in battery shapes and specifications across different electric motorcycle brands, the size of the battery, the weight of the battery and the quality of the battery. These issues complicate the universal application of the method, hindering the convenience that the system is meant to provide plus lack in building the confidence among the consumers. However, amid these challenges, a groundbreaking innovation from IMOTO Indonesia has emerged, poised to revolutionize the electric motorcycle industry in Indonesia.</p>
<br><p>At the Global Debut of VISION.ev event held in July 2024, Doddy Lukito, Co-Founder of IMOTO Indonesia, introduced a groundbreaking new battery technology called IMOTO Rapid Battery. This battery is not only a product of local ingenuity but also features advanced and cutting-edge benefits designed to meet the needs of electric motorcycle users in Indonesia.
</p>
<br>
<img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/content7a.webp" alt="gambar baterai motor listrik">
<p class="text-xs">Source: imoto </p>
<br><p>IMOTO Rapid Battery is the pioneer of super fast charging technology in Indonesia. With this technology, users only need 30 minutes to recharge the battery from 20% to 80%. This charging speed offers tremendous benefits, reducing wait times, customer convenience of not swapping the battery multiple times. Even more impressive is the fact that this technology is entirely homegrown, proving that Indonesia can compete in technological innovation globally.</p>

<br><p>IMOTO Indonesia proudly presents a technology that will become a real solution for electric motorcycle users in Indonesia, paving the way for a more efficient and sustainable future. Beyond its fast charging capability, IMOTO Rapid Battery will meet global standards with its exceptional battery durability and safety standards. The latest technology embedded in IMOTO Rapid Battery makes it not only more powerful but also practical for everyday use as a primary vehicle.</p>
<br><p>IMOTO will continuously thrive to provide the better Tech in every model it will introduce.</p>
  `,
    related: ["1", "2", "3", "4", "5", "6", "8", "9"],
  },
  {
    id: "8",
    title:
      "Battery Lasts All Day! Here Are 8 Smart Tips to Save Electric Motorcycle Battery",
    label: "Article",
    slug: "mau-baterai-awet-coba-trik-hemat-baterai-motor-listrik-ini",
    image:
      "https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/content8.jpg",
    description:
      "Baterai tahan seharian! Temukan 8 trik jitu hemat baterai motor listrik untuk perjalanan lebih nyaman, ramah lingkungan, dan bebas polusi!",
    content: `<div class="font-bold mb-5"><p>Have you ever imagined riding without the roar of exhaust or worrying about pollution? Electric motorcycles are the answer, offering you a more enjoyable, eco-friendly, and energy-efficient riding experience. Powered by electric motors, these vehicles are emission-free and provide the comfort of a quiet ride, unlike conventional motorcycles.
</p></div>
<br><p>As a new user, you might find it strange at first when you twist the throttle and hear nothing. But that’s one of the advantages of electric bikes, silent yet powerful. One great example is the Octa GT from IMOTO Indonesia, equipped with advanced technology and offering multiple riding modes tailored to your needs.</p>
<br><p>IMOTO OCTA GT comes with two riding modes, D (Drive)  and S (Sports). D mode lets you cruise at speeds up to 45 km/h, perfect for relaxed, energy-saving rides. Meanwhile, S mode can take you up to 70 km/h, ideal for situations that require quicker acceleration. What’s more, you can switch between modes seamlessly, even while riding, giving you full control on the go. With built-in cruise control, maintaining a constant speed becomes effortless, helping you conserve battery power for longer, more efficient rides.

Additionally, IMOTO OCTA GT is equipped with a Reverse feature, making it easier to move backward, especially useful when parking or navigating tight spaces.</p>
<br>
<img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/content8a.jpg" alt="gambar detail komponen motor">
<p class="text-xs">Source: imoto </p>
<br><p>Electric motorcycles are also known to be more energy-efficient than conventional ones, as battery energy is used more optimally. However, here are a few tips to make your IMOTO OCTA GT even more energy-efficient.</p>

<br><p>IMOTO Indonesia proudly presents technology that will provide a real solution for electric motorcycle users in Indonesia, paving the way towards a more efficient and sustainable future. In addition to its fast battery charging capability, the IMOTO Rapid Battery meets global standards with exceptional battery durability and high safety standards. The latest technology embedded in the IMOTO Rapid Battery makes it not only stronger but also practical for daily use as a primary vehicle.</p>

<br><br><p>Here are 8 energy-saving tips from IMOTO Indonesia:</p>
<br>
<p class="mr-3 font-semibold">1. Smooth Acceleration </p>   
<p>Avoid sudden throttle twists. Smooth acceleration helps save battery energy.</p>
</div>
<br>
 <p class="mr-3 font-semibold">2. Choose ECO Mode.
</p>
<p>Use ECO mode to optimize energy usage, especially in congested city traffic.
<br>
<br>
<p class="mr-3 font-semibold">3. Lighten Your Load</p>
<p>Avoid carrying excessive weight, as it forces the motor to work harder, consuming more energy.
<br>
<br>
<p class="mr-3 font-semibold">4. Avoid Overcharging</p>
<p>Don’t overcharge your battery, as it can shorten its lifespan.</p>
<br>
<p class="mr-3 font-semibold">5. Check Tire Pressure </p>
<p>Ensure your tires are inflated to the manufacturer’s recommendations. Low tire pressure increases energy consumption.

</p>
<br>
<p class="mr-3 font-semibold">6. LRegular Maintenance </p>
<p>Keep your electric motorbike in top condition by performing regular maintenance to ensure efficiency.</p>
<br>
<p class="mr-3 font-semibold">7. Use Navigation Apps  </p>
<p>Navigation apps can help you find the fastest and most efficient routes, saving time and energy.
</p>
<p class="mr-3 font-semibold">8. Limit Excess Accessories</p>
<p>Avoid using energy-draining accessories like additional lights or heaters, as these can increase energy consumption.
</p>
<br>
<p>By applying these simple tips, the IMOTO OCTA GT can become your best companion for daily commuting, not just environmentally friendly, but also highly efficient. Experience the comfort of riding an electric motorbike with no pollution and greater energy savings, all with IMOTO!
</p>
<br>
  `,
    related: ["1", "2", "3", "4", "5", "6", "7", "9"],
  },
  {
    id: "9",
    title: "IMOTO Indonesia Stuns with Next-Gen EV Innovations at Tech in Asia",
    label: "Event",
    slug: "IMOTO-Indonesia-Memukau-Tech-in-Asia-dengan-Inovasi-EV-canggihnya",
    image: `${assetBaseUrl}/image/contents/TIA1.jpg`,
    description:
      "IMOTO Indonesia mencuri perhatian di Tech in Asia Conference 2024 dengan memamerkan kendaraan listrik andalan, VISION.ev, bersama deretan teknologi inovatif yang siap mengubah masa depan mobilitas di Indonesia.",
    content: `<div class="font-bold mb-5"><p>IMOTO Indonesia made a significant splash at the Tech in Asia Conference 2024, showcasing its flagship electric vehicle, the VISION.ev, alongside an impressive array of innovative technologies designed to reshape the future of mobility in Indonesia.

</p></div>
<br><p class="text-xl font-semibold">A Showcase of Innovation</p>
<img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/TIA2.jpg" alt="gambar detail komponen motor">
<br><p>At the conference, Abhisek Yadav as COO, and Dodi Lukito as CIO of IMOTO Indonesia, took center stage on the first day to present their vision. They highlighted an impressive range of technologies featured at the Tech in Asia Conference 2024, capturing the interest of all attendees. Throughout the thrilling two-day event, IMOTO Indonesia enthralled the audience with pioneering developments, including:
</p><br>
<p class="mr-3 font-semibold">1. IMOTO Futech Frame:</p>   
<p>This highly scalable and modular platform combines lightweight design with high performance, setting a new standard for electric vehicles.</p>
</div>
<br>
 <p class="mr-3 font-semibold">2. IMOTO NEXT-GEN VCU:
</p>
<p>An advanced vehicle control unit that offers easy monitoring and seamless integration with various platforms.
<br>
<br>
<p class="mr-3 font-semibold">3. Rapid Fast Charge Stations:</p>
<p>With plans for 200 network points, these stations support the pioneering IMOTO RAPID BATTERY, enabling rapid charging from 20% to 80% in just 30 minutes.
<br>
<br><p class="text-xl font-semibold">A Vision for the Future</p><br/>
<img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/TIA3.jpg" alt="gambar detail komponen motor">
<br><p>IMOTO Indonesia is driven by a bold ambition: to establish itself as Indonesia’s premier electric two-wheeler manufacturer, positioning the nation as a hub for the electric vehicle (EV) industry. With a commitment to achieving a 75% domestic content level, IMOTO not only focuses on producing world-class innovations but also aims to generate new job opportunities and nurture local talent within the EV sector.</p><br>
<br><p>The company envisions providing clean, green, economical, and efficient mobility solutions for the Indonesian populace, embodying a sustainable approach to transportation.
</p><br>
<br><p class="text-xl font-semibold">Proudly Designed in Indonesia</p>
<br><p>IMOTO Indonesia stands tall as a beacon of innovation, proudly proclaiming that its technologies are designed and developed in Indonesia for the world. This local initiative not only champions the nation’s talent but also emphasizes the potential of Indonesia to lead in the global EV market.
</p>

<br><p>As the demand for sustainable transportation continues to rise, IMOTO Indonesia is poised to play a pivotal role in shaping the future of mobility in Southeast Asia and beyond. The excitement generated at the Tech in Asia Conference is just the beginning of an exhilarating journey toward a greener future.
</p>
  `,
    related: ["1", "2", "3", "4", "5", "6", "7", "8"],
  },
];
