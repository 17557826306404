<template>
  <navbar-hide class="sticky top-0" />

  <div class="snap-y relative snap-mandatory w-screen overflow-scroll">
    <div class="min-h-screen bg-white antialiased text-black">
      <main class="pt-16 lg:pt-24">
        <div
          class="flex flex-col lg:flex-row justify-between p-8 mx-auto max-w-screen-xl"
        >
          <article
            class="w-full lg:w-3/4 mx-auto max-w-2xl format format-sm sm:format-base lg:format-lg format-blue"
          >
            <header class="mb-4 lg:mb-6 not-format">
              <h1
                class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl"
              >
                {{ article.title }}
              </h1>
            </header>
            <div v-if="article.image" class="relative inline-block">
              <span
                :class="{
                  'bg-green-200 text-green-600': article.label === 'Article',
                  'bg-blue-200 text-blue-600': article.label === 'Event',
                }"
                class="absolute top-0 left-0 text-sm font-semibold px-2 py-1 rounded-tl-lg rounded-br-lg"
              >
                {{ article.label }}
              </span>
              <img
                :src="article.image"
                :alt="article.title"
                class="mb-5 rounded-lg"
              />
            </div>
            <div v-html="article.content"></div>
          </article>

          <aside
            aria-label="Related articles"
            class="lg:ml-8 py-8 lg:py-0 lg:w-1/4"
          >
            <div>
              <div class="flex justify-between items-center mb-5">
                <h2 class="text-2xl font-bold text-blue-600">More Contents</h2>
              </div>
              <div class="grid grid-flow-col justify-stretch space-x-4 mb-4">
                <button
                  @click="setFilter('All')"
                  :class="{
                    'bg-sky-100 text-blue-500 font-semibold':
                      selectedFilter === 'All',
                  }"
                  class="px-4 py-2 bg-gray-200 rounded text-gray-500"
                >
                  All
                </button>
                <button
                  @click="setFilter('Article')"
                  :class="{
                    'bg-sky-100 text-blue-500 font-semibold':
                      selectedFilter === 'Article',
                  }"
                  class="px-4 py-2 bg-gray-200 rounded text-gray-500"
                >
                  Article
                </button>
                <button
                  @click="setFilter('Event')"
                  :class="{
                    'bg-sky-100 text-blue-500 font-semibold':
                      selectedFilter === 'Event',
                  }"
                  class="px-4 py-2 bg-gray-200 rounded text-gray-500"
                >
                  Event
                </button>
              </div>
              <div class="grid gap-12 sm:grid-cols-2 lg:grid-cols-1">
                <article
                  v-for="related in paginatedFilteredArticles"
                  :key="related.id"
                  class="max-w-screen"
                >
                  <router-link
                    :to="{
                      name: 'Article',
                      params: { id: related.id, slug: related.slug },
                    }"
                  >
                    <div class="relative inline-block">
                      <span
                        :class="{
                          'bg-green-200 text-green-600':
                            related.label === 'Article',
                          'bg-blue-200 text-blue-600':
                            related.label === 'Event',
                        }"
                        class="absolute top-0 left-0 text-xs font-semibold px-2 py-1 rounded-tl-lg rounded-br-lg"
                      >
                        {{ related.label }}
                      </span>
                      <img
                        :src="related.image"
                        class="mb-2 rounded-lg"
                        :alt="related.title"
                      />
                    </div>

                    <h2
                      class="mt-2 text-lg font-bold leading-tight text-gray-900"
                    >
                      {{ related.title }}
                    </h2>
                  </router-link>
                </article>
              </div>

              <!-- Pagination Controls -->
              <div class="flex justify-between mt-6">
                <button
                  @click="prevPage"
                  :disabled="currentPage === 1"
                  class="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
                >
                  Previous
                </button>
                <button
                  @click="nextPage"
                  :disabled="currentPage === totalPages"
                  class="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            </div>
          </aside>
        </div>
      </main>
      <Footer class="text-white" />
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import NavbarHide from "../components/NavbarHide.vue";
import { articles as articlesEn } from "../articles-en.js";
import { articles as articlesId } from "../articles-id.js";
import { useI18n } from "vue-i18n"; // Pastikan i18n digunakan

export default {
  name: "Article",
  components: {
    Footer,
    NavbarHide,
  },
  data() {
    return {
      assetBaseUrl: import.meta.env.VITE_STATIC_ASSET_BASE_URL,
      article: {},
      relatedArticles: [],
      selectedFilter: "All",
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  computed: {
    // Mendapatkan daftar artikel berdasarkan bahasa yang dipilih
    articles() {
      return this.$i18n.locale === "id" ? articlesId : articlesEn;
    },
    totalPages() {
      return Math.ceil(this.filteredArticles.length / this.itemsPerPage);
    },
    filteredArticles() {
      if (this.selectedFilter === "All") {
        return this.relatedArticles;
      }
      return this.relatedArticles.filter(
        (article) => article.label === this.selectedFilter
      );
    },
    paginatedFilteredArticles() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredArticles.slice(start, end);
    },
  },
  watch: {
    "$i18n.locale": {
      handler() {
        this.fetchArticle(); // Memanggil ulang fetchArticle saat bahasa berubah
      },
      immediate: true, // Panggil watcher ini saat pertama kali komponen dimuat
    },
    "$route.params.slug": {
      handler() {
        this.fetchArticle();
      },
      immediate: true,
    },
  },
  methods: {
    setFilter(filter) {
      this.selectedFilter = filter;
      this.currentPage = 1;
    },
    fetchArticle() {
      const slug = this.$route.params.slug;
      this.article =
        this.articles.find((article) => article.slug === slug) || {};
      this.fetchRelatedArticles();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    fetchRelatedArticles() {
      if (this.article && this.article.related) {
        this.relatedArticles = this.article.related.map((relatedId) =>
          this.articles.find((article) => article.id === relatedId)
        );
      } else {
        this.relatedArticles = [];
      }
      this.currentPage = 1;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
};
</script>
