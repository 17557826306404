import { createRouter, createWebHistory } from "vue-router";
import HomePage from "./src/pages/HomePage.vue";
import Article from "./src/pages/Article.vue";
import OctaGT from "./src/pages/OctaGT.vue";
import { articles } from "./src/articles-id.js";
import AboutNew from "./src/pages/AboutNew.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: {
      title: "Home Page",
      metaTags: [
        {
          name: "description",
          content:
            "The home page of our example app, max 150-160 char lansing page",
        },
        {
          property: "og:description",
          content: "The home page of our example app.",
        },
      ],
    },
  },
  {
    path: "/about",
    name: "About",
    component: AboutNew,
    meta: {
      title: "About",
      metaTags: [
        {
          name: "description",
          content:
            "The home page of our example app, max 150-160 char about page",
        },
        {
          property: "og:description",
          content: "The home page of our example app.",
        },
      ],
    },
  },
  {
    path: "/product/OctaGT",
    name: "Octa GT",
    component: OctaGT,
    meta: {
      title: "Octa GT",
      metaTags: [
        {
          name: "description",
          content:
            "The home page of our example app, max 150-160 char octa page",
        },
        {
          property: "og:description",
          content: "The home page of our example app.",
        },
      ],
    },
  },
  {
    path: "/article/:id/:slug?",
    name: "Article",
    component: Article,
    meta: {
      title: "Article",
      metaTags: [
        {
          name: "description",
          content: "The home page of our example app, max 150-160 char",
        },
        {
          property: "og:description",
          content: "The home page of our example app.",
        },
      ],
    },
  },
  {
    path: "/article",
    redirect: (to) => {
      console.log("Redirecting to default article...");
      console.log("Articles available:", articles); // Debugging log

      const defaultArticle = articles.find((article) => article.id === "9");

      if (!defaultArticle) {
        console.error("Default article not found!");
        return { name: "Home" };
      }

      console.log("Default article found:", defaultArticle); // Debugging log

      return {
        name: "Article",
        params: { id: "1", slug: defaultArticle.slug },
      };
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let metaTitle = "Judul default";
  let metaTags = [
    {
      name: "description",
      content: "The default description",
    },
    {
      property: "og:description",
      content: "The default description for Open Graph.",
    },
  ];

  if (to.name === "Article" && to.params.id) {
    const article = articles.find((a) => a.id == to.params.id);
    if (article) {
      metaTitle = article.title;
      metaTags = [
        {
          name: "description",
          content: article.content.substring(0, 150), // contoh, sesuaikan sesuai kebutuhan
        },
        {
          property: "og:description",
          content: article.content.substring(0, 150),
        },
      ];
    }
  } else if (to.meta && to.meta.title) {
    metaTitle = to.meta.title;
    metaTags = to.meta.metaTags || metaTags;
  }

  document.title = metaTitle;

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
