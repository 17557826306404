const assetBaseUrl = import.meta.env.VITE_STATIC_ASSET_BASE_URL;

export const articles = [
  {
    id: "1",
    title: "Mitos tentang Motor Listrik yang bikin ragu, Begini penjelasannya.",
    label: "Article",
    slug: "Mitos-tentang-Motor-Listrik-yang-bikin-ragu",
    image: `${assetBaseUrl}/image/contents/mitos.jpg`,
    description: "Deskripsi untuk artikel mitos",
    content: `
      <p class="text-xl font-medium mb-5">
        Kalian pasti sering dengar berbagai informasi simpang siur tentang motor listrik, eits.. jangan langsung percaya ya dengan informasi yang sumbernya belum jelas, berikut mitos - mitos yang tersebar dan membuat ragu untuk pindah ke motor listrik beserta fakta yang sebenarnya, berikut 4 mitos yang berkembang di masyarakat beserta fakta sesungguhnya :
      </p>
      <h5 class="text-md font-bold my-4">1. Tidak Ramah Lingkungan</h5>
      <p><b>Mitos:</b> Kendaraan listrik tidak ramah lingkungan karena dengan memproduksi baterai maka akan mencemari lingkungan</p>
      <p><b>Faktanya:</b> Kendaraan listrik memiliki jejak karbon keseluruhan yang lebih rendah
dibandingkan dengan jejak yang ditinggalkan oleh kendaraan berbahan bakar fosil.
Seiring dengan berkembangnya teknologi, proses produksi baterai tetap menjadi
pilihan yang akan lebih ramah bagi lingkungan. Selain itu, energi yang digunakan
untuk mengisi daya bateai pada kendaraan listrik semakin banyak yang berasal dari
sumber energi terbarukan.</p>

      <h5 class="text-md font-bold my-4">2. Jarak tempuh sangat pendek</h5>
      <p><b>Mitos:</b> Jarak tempuh kendaraan listrik relatif lebih pendek dari kendaraan berbahan
bakar fosil.</p>
      <p><b>Faktanya:</b> Kendaraan listrik modern dapat mengakomodasi kebutuhan harian
kebanyakan orang karena dapat menjangkau jarak tempuh yang tinggi.Pada
beberapa model terbaru mobil listrik bahkan bisa mencapai 200-700 km dan motor
listrik ada yang dapat menjangkau hngga 200 km dengan sekali pengisian daya.
Dengan infrastruktur pengisian daya yang kini semakin berkembang tentunya akan
membuat perjalanan jarak jauh menjadi lebih praktis.</p>

      <h5 class="text-md font-bold my-4">3. Susah ngecas?</h5>
      <p><b>Mitos:</b> Infrastruktur pengisian baterai tidak memadai</p>
      <p><b>Faktanya:</b> Meski saat ini memang belum sebanyak tempat pengisian bahan bakar
fosil (POM Bensin), Namun Infrastruktur pengisian daya untuk kendaraan listrik terus
berkembang pesat kini. Banyak kota besar dan jalan raya utama sekarang yang
menyediakan stasiun pengisian cepat. Selain itu, kini banyak rumah dan tempat kerja
juga mulai memasang stasiun pengisian daya pribadi yang berkolaborasi dengan
pemerintah dan swasta untuk terus memperbanyak penyediaan SPKLU dan
SPBKLU di Indonesia.</p>

      <h5 class="text-md font-bold my-4">3. Ngecasnya lama</h5>
      <p><b>Mitos:</b> Pengisian baterai memerlukan waktu yang lama, Tidak efisien.</p>
      <p><b>Faktanya:</b> Waktu pengisian daya adaendaraan listrik bervariasi tergantung pada
jenis charger yang digunakan. Contohnya pada motor listrik keluaran terbaru iMoto
Indonesia yang memiliki Fast Charger mampu mengisi daya hingga 80% hanya
dalam waktu 30 menit saja. Bahkan untuk jenis Ultra Fast Charger hanya memakan
waktu 15 menit saja.</p><br>

      <p>Dengan fakta dari mitos - mitos yang berkembang diatas, seharusnya judah
menjawab keraguan untuk menggunakan kendaraan listrik.
<br>
Ikuti sosialmedia iMoto indonesia untuk informasi yang lebih banyak lagi tentang
motor listrik.</p>

 

    `,
    related: ["2", "3", "4", "5", "6", "7", "8", "9"],
  },
  {
    id: "2",
    title: "Keuntungan punya motor listrik di zaman sekarang",
    label: "Article",
    slug: "keuntungan-punya-motor-listrik",
    image: `${assetBaseUrl}/image/contents/keuntungan.jpg`,
    description: "deskripsi pada konten keuntungan punya motor listrik",
    content: `

    
    <p>Dalam zaman modern ini, kesadaran masyarakat mengenai isu lingkungan semakin meningkat. Hal ini membuat banyak pabrikan di sektor otomotif yang berlomba lomba menciptakan inovasi teknologi yang dikembangkan guna membantu mengurangi dampak negatif terhadap lingkungan, Salah satu contohnya adalah iMoto indonesia yang menciptakan motor listrik yang dirancang di indonesia oleh anak bangsa.
    <br><br>Motor listrik adalah inovasi revolusioner di dunia transportasi, Yang semakin hari semakin mengalami perkembangan yang signifikan sejak awal kemunculannya. Menurut ketua Umum Asosiasi Industri Sepeda Motor Listrik Indonesia (AISMOLI), Budi Setiyadi mengatakan populasi motor listrik di indonesia kini sudah mencapai 100 ribu unit.
    <br><br>Untuk mendorong perkembangan industri motor listrik pemerintah melalui Kemenperin bahkan telah memberikan subsidi berupa insentif untuk setiap pembelian motor listrik sebanyak Rp 7 juta per unit. dengan tujuan membuat harga motor listrik menjadi lebih terjangkau bagi masyarakat. Hal ini diungkapkan oleh Menteri Koordinator Bidang Kemaritiman dan Investasi, Luhut Binsar Pandjaitan, yang menyatakan bahwa insentif tersebut diperlukan untuk mendorong pertumbuhan industri sepeda motor dan mobil listrik di Tanah Air. Menurutnya, pemerintah memiliki ambisi untuk memproduksi sepeda motor listrik sebanyak 1,2 juta unit dan mobil listrik sebanyak 75.000 unit pada tahun 2024. Ia juga menyebutkan bahwa langkah ini mengikuti kebijakan banyak negara yang telah memberikan subsidi untuk pembelian kendaraan listrik, dan hal ini telah diatur dalam Peraturan Menteri Perhubungan (P) Nomor 44 Tahun 2020 tentang uji lapangan tipikal dan PM Nomor 45 Tahun 2020 tentang kendaraan tertentu yang menggunakan motor listrik.
    <br><br>Kini motor listrik semakin canggih dengan menyematkan teknologi IoT (Internet of Things) dan terhububung dengan ponsel, Seperti aplikasi yang diusung Imoto Indonesia yang dilengkapi berbagai fitur mutakhir, aplikasi ini dirancang untuk meningkatkan pengalaman berkendara dan membantu mendapatkan manfaat maksimal dari kendaraan listrik. Salah satu fitur utama aplikasi adalah kemampuan untuk menghidupkan dan mematikan sepeda motor EV (Electric Vehicle) dari jarak jauh langsung melalui ponsel pribadi pengendara. Hal ini memungkinkan Anda dengan cepat dan mudah menghidupkan kendaraan dan pergi ke jalan, tanpa perlu kunci atau kontak fisik dengan sakelar dan berbagai firut canggih lain yang akan memudahkan pengguna saat mengendarai motor listrik dari iMoto Indonesia.</p>
    
    <p class="text-xl font-medium mt-10">
        Selain itu, Apalagi sih kelebihan yang membuat kita harus pindah dari motor berbahan bakar fosil ke motor listrik? Kelebihan ini dapat Anda rasakan dalam kehidupan sehari-hari. Adapun untuk keunggulan tersebut telah kami rangkum di bawah ini.
      </p>
      <br>

      <div class="flex">
      <p class="mr-3 font-medium">1.</p>
      <p>Kendaraan listrik tidak menghasilkan emisi gas buang yang menyebabkan polusi udara</p>
      </div>
      <div class="flex">
      <p class="mr-3 font-medium">2.</p>
      <p>Motor listrik hadir untuk menjawab tantangan kelangkaan energi minyak. Dengan adanya kendaraan listrik, maka bisa lebih menghemat penggunaan BBM.</p>
      </div>
      <div class="flex">
      <p class="mr-3 font-medium">3.</p>
      <p>Biaya perawatan motor listrik jauh lebih hemat karena hanya perlu perawatan bagian baterai dan kondisi mesin. Hal ini akan membuat Anda lebih hemat dalam perawatan kendaraan listrik yang dimiliki.</p>
      </div>
      <div class="flex">
      <p class="mr-3 font-medium">4.</p>
      <p>Dapat berakselerasi dengan lebih leluasa karena bobot yang lebih ringan</p>
      </div>
      <div class="flex">
      <p class="mr-3 font-medium">5.</p>
      <p>Lebih nyaman dan tidak bising, motor listrik tidak akan mengeluarkan bunyi bising sama sekali karena Bunyi pada mesin motor listrik bersumber dari putaran dinamo atau rotor.</p>
      </div>
      <div class="flex">
      <p class="mr-3 font-medium">6.</p>
      <p>Motor listrik dapat dicas di rumah. Rata-rata pengguna biasanya hanya perlu mengisi daya sebanyak dua kali dalam seminggu.</p>
      </div>

      <br><br>Tidak perlu khawatir soal tagihan listrik rumah yang akan membengkak, karena motor listrik ini cukup hemat. Untuk Jarak tempuh 40 km hanya membutuhkan energi listrik sebesar 1 kWh. Bahkan jika 2nda mengisi daya di tempat pengisian, Anda hanya perlu mengeluarkan uang 2 ribu rupiah saja.
      <br><br>Biaya ini tentunya jauh lebih murah meriah jika dibandingkan dengan membeli BBM saat memakai kendaraan konvensional. Terlebih lagi harga BBM di Indonesia sudah naik, jadi kini sudah saatnya kita beralih ke motor listrik untuk menjadi kendaraan sehari hari.</p>
`,
    related: ["1", "3", "4", "5", "6", "7", "8", "9"],
  },
  {
    id: "3",
    title: "Motor listrik bisa meledak? Lakukan hal berikut agar tetap aman.",
    label: "Article",
    slug: "motor-listrik-bisa-meledak?",
    image: `${assetBaseUrl}/image/contents/meledak.jpg`,
    description: "Deskripsi untuk artikel meledak",
    content: `<p>Dengan meningkatnya popularitas dan populasi motor listrik dikalangan masyarakat modern kini ternyata juga diiringi dengan banyaknya kasus kebakaran yang menimpa Motor listrik tersebut. Pertanyaan yang sering muncul adalah apakah motor listrik bisa terbakar? Ya, Motor bertenaga listrik memang bisa mengalami kebakaran. Hal ini bisa terjadi karena beberapa aspek salah satunya kelalaian saat memodifikasi Motor dimana spare part yang diganti tidak sesuai dengan spesifikasi Motor atau kesalahan saat merakit ulang setelah melakukan modifikasi dapat menjadi pemicu tegangan listrik yang menjadi tidak stabil saat melakukanpengisian daya dan juga karena overheating (baterai terlalu panas).</p>
    <br><br><p>Untuk meminimalisasi risiko terjadinya hal yang tidak diinginkan oleh pengguna motor listrik disarankan untuk melakukan pengecekan berkala tiap 2-3 bulan untuk melihat kondisi baterai dan kabel-kabel yang ada pada unit. Ada beberapa hal penting yang harus diperhatikan seperti adanya kebocoran pada kutub-kutub baterai. juga adanya kabel yang mungkin terjepit atau putus. Berikut beberapa tips yang bisa dilakukan pengguna motor listrik agar tetap aman digunakan :
</p>
<br>
<div class="flex">
      <p class="mr-3 font-medium">1.</p>
      <p>Jangan biarkan baterai dalam kondisi sepenuhnya habis sebelum dicas. Sebaiknya usahakan untuk cas saat sudah sampai rumah meski baterai yang tersisa 20%.
</p>
      </div>
<div class="flex">
      <p class="mr-3 font-medium">2.</p>
      <p>Hindari tejadinya overcharging atau mengecas setelah baterai sudah penuh. Rata - rata untuk maksimal mengecas cukup 4 jam saja. dan jika sebelum 4 jam sudah penuh, sebaiknya langsung dicabut. HIndari mengecas Motor semalaman.</p>
      </div>
<div class="flex">
      <p class="mr-3 font-medium">3.</p>
      <p>Jika setelah 4 jam baterai belum penuh saat dicas, maka lebih baik dicabut terlebih dahulu dan diistirahatkan selama 20-30 menit.</p>
      </div>
<div class="flex">
      <p class="mr-3 font-medium">4.</p>
      <p>Selalu lakukan pengecasan di luar ruangan dan tidak langsung terkena matahari.
</p>
      </div>
<div class="flex">
      <p class="mr-3 font-medium">5.</p>
      <p>Apabila ingin mengecas setelah Motor digunakan, istirahatkan dulu selama 15 menit sebelum dicas.
Jangan biarkan baterai dalam kondisi sepenuhnya habis sebelum dicas. Sebaiknya usahakan untuk cas saat sudah sampai rumah meski baterai yang tersisa 20%.
</p>
      </div>
<div class="flex">
      <p class="mr-3 font-medium">6.</p>
      <p>Jika Motor listrik yang kamu miliki terdapat beberapa baterai dan salah satu baterainya bermasalah, sebaiknya segera diganti agar tidak berdampak pada baterai yang lain di masa mendatang.
</p>
      </div>

      <br><br><p>6 poin tadi berguna bagi pemilik Motor listrik karena dapat pengecekan dan
perawatan mudah sendiri sehingga tidak perlu khawatir lagi dengan kasus motor listrik yang meledak.</p>
`,
    related: ["1", "2", "4", "5", "6", "7", "8", "9"],
  },
  {
    id: "4",
    title:
      "Gimana cara menjaga performa baterai motor listrik agar tetap prima? Ini tips ala iMoto Indonesia.",
    label: "Article",
    slug: "cara-menjaga-performa-baterai-motor-listrik",
    image:
      "https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/hemat.jpg",
    content: `
    <p>Kita sepakat bahwa biaya pengoperasian sebuah Motor listrik jauh lebih murah jika dibandingkan dengan motor bensin karena biaya pengisian daya baterai sebagai sumber tenaga sebuah motor listrik memberikan biaya operasional yang jauh lebih rendah daripada bahan bakar bensin. Motor listrik juga memiliki efisiensi yang lebih tinggi, yang berarti jarak tempuh per kWh lebih baik daripada jarak tempuh per liter bahan bakar.
</p>
 <br><br><p>Namun yang sering jadi pertanyaan dan membuat ragu calon pengguna motor listrik adalah “Apa baterai motor listrik bisa berumur panjang?”. Nah.. untuk menjawab pertanyaan tersebut, Berikut tips ala iMoto Indonesia agar baterai motor listrik bisa awet meski sering dipakai.</p>
<br>
<p class="mr-3 font-semibold">1. Isi daya baterai motor listrik secara berkala dan teratur, Jangan tunggu sampai 0% ya!</p>   
<p>Baterai motor listrik tetap harus diisi ulang setidaknya setiap dua hari atau tiga hari sekali agar performanya tetap terjaga meski motornya jarang kamu gunakan, Kalau sering dipakai usahakan isi daya minimal saat 20% untuk merawat performa baterai tetap prima, Karena kebiasaan menghabiskan baterai hingga 0% dapat merusak komponen dan dapat mengacaukan kinerja baterai.</p>
</div>
<br>
 <p class="mr-3 font-semibold">2. Jangan mengisi daya baterai temperatur panas.
</p>
<p>Tunggu beberapa saat hingga temperatur motor lebih dingin saat akan mengisi daya untuk menghindari kerusakan komponen pada baterai, Selain itu melakukan pengisian daya ketika temperatur motor panas dapat mengakibatkan overheating yang pada dalam kasus ekstrem, dapat memicu kebakaran atau ledakan.
<br>
<br>
<p class="mr-3 font-semibold">3. Usahakan parkir ditempat teduh atau tidak terkena matahari langsung.</p>
<p>Sinar matahari membuat panas pada baterai lithium yang dipakai motor listrik dan itu dapat merusak masa guna pakai baterai jadi tidak bertahan lama, oleh karena itu hindari parkir di tempat yang terpapar sinar matahari secara langsung.
<br>
<br>
<p class="mr-3 font-semibold">4. Gunakan alat pengisian daya yang original</p>
<p>Selalu gunakan pengisi daya yang direkomendasikan oleh pabrikan. Pengisi daya yang tidak sesuai dapat merusak baterai karena dapat membuat banyak proses pemanasan dalam waktu singkat sehingga dapat merusak komponen yang ada pada baterai.</p>
<br>
<p class="mr-3 font-semibold">5. Jaga kebersihan motor listrik</p>
<p>Pastikan baterai dan konektor selalu dalam keadaan yang bersih dan bebas dari kotoran atau korosi karena Kotoran dan korosi dapat mengurangi efisiensi pengisian dan pelepasan daya baterai pada motor listrik.</p>
<br>
<p class="mr-3 font-semibold">6. Hidari benturan yang keras</p>
<p>Komponen yang menempel pada permukaan akan terguncang dan dikhawatirkan akan lepas jika baterai terkena benturan yang keras, selain itu performa komponen baterai juga akan menurun dan akan mengakibatkan kerusakan atau kebakaran jika baterai terbentur secara keras</p>
<br>
<p class="mr-3 font-semibold">7. Hindari overload pada penggunaan motor</p>
<p>Overload atau membawa motor dengan melebihi kapasitas motor dapat membebani baterai dan komponen lainnya, mengurangi efisiensi dan mempercepat keausan.</p>
<br>
<p>Demikianlah tips ala imoto Indonesia untuk menjaga performa baterai motor listrik, Segera pesan motor listrik imoto Indonesia dan rasakan berbagai keunggulan yang dimilikinya. Mau tahu informasi selanjutnya? Jangan lupa follow instagram kami @imoto_indonesia.</p>
<br>
`,
    related: ["1", "2", "3", "5", "6", "7", "8", "9"],
  },
  {
    id: "5",
    title:
      "IMOTO Global Debut VISION.ev: Motor Listrik Buatan Indonesia untuk Dunia dengan Keunggulan Pengisian Daya Cepat hanya 30 Menit",
    label: "Event",
    slug: "imoto-vision-motor-listrik-buatan-indonesia?",
    image: `${assetBaseUrl}/image/contents/debut-vision.jpg`,
    description: "Deskripsi untuk artikel debut",
    content: `<div class="font-bold mb-5"><p>Dirancang dan Dikembangkan Sepenuhnya di Indonesia dengan Target TKDN 75%, VISION.ev adalah Tech Gadget Terbaru dalam Industri Sepeda Motor Listrik</p></div>
    <div class="font-semibold italic"><p>Dirancang dan Dikembangkan Sepenuhnya di Indonesia dengan Target TKDN 75%, VISION.ev adalah Tech Gadget Terbaru dalam Industri Sepeda Motor Listrik
</p></div>
<br><p><b>Jakarta, 16 July 2024</b> – IMOTO dengan bangga memperkenalkan inovasi terbarunya, VISION.ev, pada acara Global Debut yang diadakan di Sheraton Hotel Gandaria, Jakarta pada 16 Juli 2024. VISION.ev akan menjadi motor listrik pertama di Indonesia dengan target capaian Tingkat Komponen Dalam Negeri (TKDN) sebesar lebih dari 75% yang siap dipasarkan pada 2025.</p>
<br><p>“Kami sangat bangga dengan peluncuran konsep VISION.ev. Kami berharap hadirnya VISION.ev nantinya dapat mendorong adopsi kendaraan listrik di Indonesia dan memperkuat posisi Indonesia di pasar global. VISION.ev kami hadirkan dengan tagline 'Made in Indonesia, for the World' yang mencerminkan komitmen IMOTO untuk membawa karya anak bangsa ke pasar internasional. Mulai dari desain hingga baterai adalah buatan lokal. Sehingga target kami, TKDN bisa mencapai 75%,” jelas Co-Founder IMOTO Indonesia, Antony Lesmana, dalam keterangan resminya pada Selasa (16/07/2024).</p>
<br><p>VISION.ev unik karena sepenuhnya dirancang dan dikembangkan di Indonesia oleh para pemuda Indonesia dalam waktu hanya 2,5 tahun. Motor ini hadir dengan berbagai keunggulan dan fitur inovatif, termasuk teknologi pengisian daya cepat yang memungkinkan baterai terisi penuh hanya dalam waktu 30 menit dari 20% hingga 80%, baterai 5,7 kWh yang membuat motor ini mampu menempuh jarak hingga 160 km dalam satu kali pengisian, kecepatan maksimum 105 km/jam yang menjadikannya kompetitif di kelasnya, fitur digital canggih yang dilengkapi dengan layar sentuh, sistem infotainment, keyless entry, dan aplikasi untuk pelacakan, geofencing, dan manajemen armada, serta desain futuristik dan ergonomis yang dirancang untuk kenyamanan dan selera pasar Indonesia.</p>
<br><p>“Dengan berbagai keunggulan yang kami hadirkan di VISION.ev, kami yakin produk ini akan menjadi solusi transportasi yang efisien dan ramah lingkungan. VISION.ev yang ramah lingkungan juga akan menyelesaikan kekhawatiran dasar konsumen terkait EV seperti pengisian daya cepat, umur baterai, jarak tempuh sekali isi, dan performa motor yang akan membantu meningkatkan adopsi EV dengan lebih cepat. VISION.ev juga merupakan jawaban bagi mereka yang mencari inovasi dan keberlanjutan dalam berkendara,” jelas Co-Founder IMOTO Indonesia, Doddy Lukito.</p>
<br><p>Doddy juga menjelaskan bahwa dalam upaya memastikan kemudahan dalam penggunaan, IMOTO saat ini juga tengah mengembangkan ekosistem pengisian daya cepat yang luas. Jaringan pengisian daya publik akan tersedia di berbagai titik strategis di perkotaan dan antar kota, dengan 200 titik pengisian yang direncanakan pada tahap pertama peluncuran.</p>
<div class="space-y-3">
<br><br><p class="font-bold">Komitmen imoto</p>
<p>IMOTO, melalui produk motor listrik unggulannya, tidak hanya menawarkan teknologi canggih tetapi juga mendukung gaya hidup berkelanjutan. Melihat bahwa masa depan industri otomotif Indonesia adalah kendaraan listrik, IMOTO akan terus berinovasi untuk membangun produk dan layanan yang membuat mobilitas bebas karbon dengan biaya adopsi yang sangat rendah.</p>
<p>“Kami percaya IMOTO akan menjadi perusahaan Zero Carbon pada tahun 2035 dengan terus mengembangkan teknologi baterai pengisian cepat dan produk-produk yang ramah lingkungan. Komitmen kami adalah untuk menyediakan mobilitas yang bersih, hijau, ekonomis, dan efisien bagi masyarakat Indonesia,” ungkap Antony.</p>
<p>Selain itu, IMOTO juga berkomitmen untuk menggunakan material lokal yang mendukung industri dalam negeri, menciptakan lapangan kerja baru dan mengembangkan bakat lokal untuk menciptakan produk global dalam industri EV.</p>
</div>
<br><p class="text-center">***</p>
<img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/debut-vision2.jpg" alt="Debut Vision 2">
<br><p>Tentang IMOTO Indonesia: Berdiri pada 2021, IMOTO merupakan Perusahaan Teknologi yang dijalankan oleh para profesional otomotif dan energi yang percaya pada Pemberdayaan Mobilitas Manusia melalui Teknologi Listrik canggih untuk dunia yang berkelanjutan. IMOTO percaya bahwa masa depan industri otomotif Indonesia adalah kendaraan listrik (EV) dan sedang membangun produk motor listrik UNTUK Indonesia, DI Indonesia, dan OLEH orang Indonesia. IMOTO bekerja untuk menjadi mandiri dalam power train EV dengan mengembangkan baterai pengisian cepat, pengontrol efisien, dan komponen lain dalam EV.</p>

  `,
    related: ["1", "2", "3", "4", "6", "7", "8", "9"],
  },
  {
    id: "6",
    title: "Kenapa Gen Z dan Milenial pilih Motor Listrik? ini alasannya",
    label: "Article",
    slug: "kenapa-gen-z-dan-milenial-pilih-motor-listrik?",
    image: `${assetBaseUrl}/image/contents/gen-z.jpg`,
    description:
      "Kenapa Gen Z dan Milenial secara masal beralih ke motor listrik seperti octa gt sebagai solusi ramah lingkungan untuk mengurangi polusi udara.",
    content: `<div class="font-bold mb-5"><p>Kenapa Gen Z dan Milenial secara masal beralih ke motor listrik seperti octa gt sebagai solusi ramah lingkungan untuk mengurangi polusi udara.</p></div>
<br><p>Generasi milenial (lahir 1983-1994) dan Gen Z (lahir 1995-2004) dikenal sebagai generasi yang penuh semangat, inovatif, dan adaptif terhadap perubahan, Mereka tumbuh di era digital yang memungkinkan mereka untuk terus berkembang dengan teknologi. Tetapi juga merangkul passion, menjalani gaya hidup berkelanjutan, Tak ayal mereka aktif pula dalam isu-isu sosial serta lingkungan. Di tengah perubahan iklim dan meningkatnya kesadaran lingkungan, Generasi Z dan Milenial menunjukkan ketertarikan yang meningkat terhadap kendaraan listrik sebagai salah satu langkah konkret untuk meminimalisasi dampak perubahan iklim dan polusi udara, terutama keterarikan mereka pada motor listrik. Berdasarkan berbagai penelitian global, ada beberapa alasan kunci di balik minat ini: faktor ekonomi, kemudahan penggunaan, dan kepedulian terhadap lingkungan. preferensi yang kuat terhadap minat membeli kendaraan listrik (electric vehicle/EV) kini menjadi Tren diantara mereka.</p>
<br><p> Mengutip dari hasil  penelitian dan survei Global, data menunjukkan bahwa baik Gen Z maupun Milenial sangat khawatir tentang perubahan iklim. Sekitar 69% Gen Z dan 73% Milenial berkomitmen untuk mengurangi dampak lingkungan dengan membeli kendaraan listrik. Data juga mengungkapkan bahwa 44% Milenial dan 44% Gen Z berencana membeli kendaraan listrik, sementara 15% Milenial dan 16% Gen Z sudah memiliki dan secara aktif berupaya meminimalisasi dampak perubahan iklim terhadap lingkungan, dengan membeli kendaraan listrik, terutama motor listrik.</p>
<img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/1.webp" alt="data statistik">
<p class="text-xs">Sumber: <a href="https://www.deloitte.com/content/dam/assets-shared/docs/campaigns/2024/deloitte-2024-genz-millennial-survey.pdf?dlva=1">https://www.deloitte.com/content/dam/assets-shared/docs/campaigns/2024/deloitte-2024-genz-millennial-survey.pdf?dlva=1</a> </p>
<br><p>Generasi muda yang sedang merintis kemandirian finansial kini lebih memilih motor listrik sebagai solusi mobilitas yang cerdas. Dengan harga yang lebih terjangkau, perawatan yang mudah, serta efisiensi bahan bakar yang tak tertandingi, motor listrik menjadi pilihan favorit.</p>
<br><img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/2.webp" alt="data statistik">
<p class="text-xs">Sumber: <a href="https://www.pwc.com/id/en/publications/automotive/indonesia-electric-vehicle-consumer-survey-2023.pdf">https://www.pwc.com/id/en/publications/automotive/indonesia-electric-vehicle-consumer-survey-2023.pdf</a> </p>
<br><p>Keputusan untuk beralih ke kendaraan listrik tidaklah sederhana, karena dipengaruhi oleh banyak faktor, mulai dari kepedulian lingkungan hingga pertimbangan ekonomi. Memahami pandangan konsumen terhadap kendaraan listrik menjadi kunci bagi industri dan pemangku kepentingan dalam menyusun strategi pemasaran yang tepat.</p>
<br><p>Data menunjukkan perbedaan menarik antara kepemilikan mobil dan sepeda motor. Sebanyak 19% responden masih menggunakan mobil berbahan bakar fosil, sedangkan hanya 5% yang telah beralih ke kendaraan hibrida atau listrik. Sementara itu, 85% responden masih mengandalkan sepeda motor berbahan bakar fosil, dan 17% lainnya telah beralih ke teknologi hibrida atau listrik. Angka-angka ini menyoroti peluang dan tantangan yang dihadapi industri kendaraan listrik ke depan.</p>
<br><img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/3.webp" alt="data statistik">
<p class="text-xs">Sumber: <a href="https://www.pwc.com/id/en/publications/automotive/indonesia-electric-vehicle-consumer-survey-2023.pdf">https://www.pwc.com/id/en/publications/automotive/indonesia-electric-vehicle-consumer-survey-2023.pdf</a> </p>
<br><p>Penelitian menunjukkan Gen Milenial memiliki semangat dan ketertarikan yang besar untuk menggunakan kendaraan listrik, berkat informasi yang diperoleh melalui media sosial dan tingkat pendidikan yang lebih tinggi. Sekitar 73% konsumen muda di Indonesia ingin memiliki motor listrik, meskipun mereka masih khawatir tentang keterbatasan stasiun pengisian baterai.</p>
<br><p>Di Indonesia, Semakin banyak brand motor listrik yang beredar dan dijual dengan rentan harga Rp14,5 juta hingga Rp70 juta tergantung merek, spesifikasi dan lainnya. Oleh sebab itu IMOTO Indonesia menghadirkan OCTA sebagai bentuk refersnsi dari Gen Milenial dan Gen Z yang dikenal sebagai generasi yang penuh semangat, inovatif, dan adaptif.</p>
<br><img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/4.webp" alt="data statistik">
<p class="text-xs">Sumber: <a href="https://www.pwc.com/id/en/publications/automotive/indonesia-electric-vehicle-consumer-survey-2023.pdf">https://www.pwc.com/id/en/publications/automotive/indonesia-electric-vehicle-consumer-survey-2023.pdf</a> </p>
<br><p>Tidak hanya berkontribusi besar dalam mengurangi polusi, OCTA juga menawarkan sentuhan keunikan pribadi bagi setiap pengendara. Dengan pilihan warna yang beragam, setiap pengguna dapat menyesuaikan motornya sesuai dengan kepribadian dan gaya hidup mereka, menjadikannya bukan sekadar alat transportasi ramah lingkungan, tetapi juga pernyataan fashion dan identitas pribadi. Bagi Generasi Z dan Milenial, yang dikenal menggunakan warna untuk mengekspresikan diri dan sikap mereka terhadap isu sosial serta lingkungan, OCTA hadir sebagai solusi yang tepat. Dengan segala kelebihan yang dimilikinya, dan siap menjadi pilihan utama bagi mereka yang ingin menggabungkan kepedulian terhadap lingkungan dengan gaya hidup yang modis dan berkelas.</p>

  `,
    related: ["1", "2", "3", "4", "5", "7", "8", "9"],
  },
  {
    id: "7",
    title: "IMOTO Rapid Battery, Revolusi Cas Motor Listrik Super Cepat",
    label: "Event",
    slug: "imoto-rapid-battery-revolusi-cas-motor-listrik-super-cepat",
    image: `${assetBaseUrl}/image/contents/content7.webp`,
    description:
      "IMOTO Rapid Battery, teknologi pengisian baterai  motor listrik super cepat hanya butuh 30 menit untuk mengisi daya dari 20% hingga 80% buatan lokal",
    content: `<div class="font-bold mb-5"><p>Seiring dengan meningkatnya popularitas motor listrik di kalangan masyarakat, semakin banyak orang yang beralih dari kendaraan berbahan bakar fosil. Alasan utamanya jelas: biaya operasional yang jauh lebih rendah, ramah lingkungan, serta beragam keuntungan lainnya yang ditawarkan oleh motor listrik. Namun, salah satu tantangan yang masih dihadapi pengguna adalah sistem battery swap yang sejauh ini dianggap sebagai solusi praktis untuk mempermudah penggunaan motor listrik. Kenyataannya, sistem ini masih sulit diimplementasikan. Mengapa demikian?</p></div>
<br><p>Meskipun sistem penukaran pada baterai swap tampak praktis pada awalnya, Sistem ini menghadapi tantangan besar, Diantaranya perbedaan bentuk dan spesifikasi pada baterai di berbagai merek sepeda motor listrik, ukuran baterai, berat baterai, dan kualitas baterai. Masalah-masalah ini mempersulit penerapan metode yang universal, sehingga menghambat kenyamanan yang seharusnya disediakan oleh sistem tersebut serta kurang dapat membangun kepercayaan di antara konsumen. Namun, di tengah tantangan-tantangan ini, sebuah inovasi terobosan dari IMOTO Indonesia muncul, siap untuk merevolusi industri sepeda motor listrik di Indonesia.</p>
<br><p>Pada acara Global Debut VISION.ev yang diadakan pada Juli 2024, Doddy Lukito, Selaku Co-Founder IMOTO Indonesia, Beliau memperkenalkan teknologi baterai revolusioner baru yang disebut IMOTO Rapid Battery. Baterai ini tidak hanya merupakan hasil kecerdikan lokal, tetapi juga dilengkapi dengan manfaat canggih dan mutakhir yang dirancang untuk memenuhi kebutuhan pengguna sepeda motor listrik di Indonesia.
</p>
<br>
<img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/content7a.webp" alt="gambar baterai motor listrik">
<p class="text-xs">Sumber: imoto </p>
<br><p>IMOTO Rapid Battery adalah pelopor teknologi pengisian super cepat di Indonesia. Dengan teknologi ini, pengguna hanya membutuhkan waktu 30 menit untuk mengisi daya baterai dari 20% hingga 80%. Kecepatan pengisian ini menawarkan manfaat luar biasa bagi penggunanya, Mampu mengurangi waktu tunggu, serta kenyamanan pelanggan karena tidak perlu sering menukar baterai. Yang lebih mengesankannya lagi, teknologi ini sepenuhnya dikembangkan di dalam negeri, Hal ini membuktikan bahwa Indonesia mampu bersaing dalam inovasi teknologi di tingkat global.</p>

<br><p>“IMOTO Indonesia dengan bangga mempersembahkan teknologi yang akan menjadi solusi nyata bagi pengguna sepeda motor listrik di Indonesia, membuka jalan menuju masa depan yang lebih efisien dan berkelanjutan. Selain kemampuan pengisian baterai cepatnya, IMOTO Rapid Battery juga memenuhi standar global dengan ketahanan baterai yang luar biasa dan standar keselamatan yang tinggi. Teknologi terbaru yang disematkan dalam IMOTO Rapid Battery menjadikannya tidak hanya lebih kuat tetapi juga praktis untuk digunakan sehari-hari sebagai kendaraan utama.</p>
<br><p>IMOTO akan terus berupaya menghadirkan teknologi terbaik di setiap model yang akan diperkenalkan.</p>
  `,
    related: ["1", "2", "3", "4", "5", "6", "8", "9"],
  },
  {
    id: "8",
    title: "Mau baterai awet? Coba Trik Hemat Baterai Motor Listrik Ini!",
    label: "Article",
    slug: "mau-baterai-awet-coba-trik-hemat-baterai-motor-listrik-ini",
    image:
      "https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/content8.jpg",
    description:
      "Baterai tahan seharian! Temukan 8 trik jitu hemat baterai motor listrik untuk perjalanan lebih nyaman, ramah lingkungan, dan bebas polusi!",
    content: `<div class="font-bold mb-5"><p>Pernahkah kamu membayangkan berkendara tanpa bisingnya suara knalpot atau tanpa memikirkan polusi yang dihasilkan?  Motor listrik adalah jawaban yang bisa kasih kamu pengalaman berkendara yang semakin menyenangkan, ramah lingkungan, dan tentunya hemat energi. Mengandalkan motor listrik sebagai penggerak utama, kendaraan ini bebas emisi dan menawarkan kenyamanan tanpa suara bising khas motor konvensional.</p></div>
<br><p>Sebagai pengguna baru, mungkin akan terasa sedikit aneh saat pertama kali menarik gas tanpa mendengar suara mesin. Namun, itulah salah satu keunggulan motor listrik, diam namun bertenaga. Salah satunya adalah Octa GT yang merupakan salah satu produk dari IMOTO Indonesa yang dilengkapi dengan teknologi canggih yang menawarkan beberapa mode berkendara yang bisa disesuaikan dengan kebutuhanmu.</p>
<br><p>IMOTO OCTA GT hadir dengan dua mode berkendara: D (Drive)  untuk kecepatan santai hingga 45 km/jam, dan S (Sport) untuk akselerasi lebih cepat hingga 70 km/jam. Uniknya, kamu bisa berganti mode kapan saja, bahkan saat berkendara! Ditambah dengan fitur Cruise Control yang menjaga kecepatan tetap tanpa perlu memutar gas, perjalanan jadi lebih nyaman dan efisien.
Selain itu, motor ini juga dilengkapi dengan fitur Reverse, yang memudahkan manuver mundur, sangat berguna saat parkir atau di tempat sempit.
</p>
<br>
<img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/content8a.jpg" alt="gambar detail komponen motor">
<p class="text-xs">Sumber: imoto </p>
<br><p>Motor listrik juga dikenal lebih efisien dibandingkan motor konvensional karena energi dari baterai digunakan lebih optimal. Namun, ada beberapa tips untuk membuat penggunaan IMOTO OCTA GT semakin hemat energi.</p>

<br><p>“IMOTO Indonesia dengan bangga mempersembahkan teknologi yang akan menjadi solusi nyata bagi pengguna sepeda motor listrik di Indonesia, membuka jalan menuju masa depan yang lebih efisien dan berkelanjutan. Selain kemampuan pengisian baterai cepatnya, IMOTO Rapid Battery juga memenuhi standar global dengan ketahanan baterai yang luar biasa dan standar keselamatan yang tinggi. Teknologi terbaru yang disematkan dalam IMOTO Rapid Battery menjadikannya tidak hanya lebih kuat tetapi juga praktis untuk digunakan sehari-hari sebagai kendaraan utama.</p>

<br><br><p>Berikut adalah 8 tips hemat energi dari IMOTO Indonesia:</p>
<br>
<p class="mr-3 font-semibold">1. Akselerasi Halus</p>   
<p>Hindari menarik gas secara tiba-tiba. Akselerasi yang halus dapat menghemat penggunaan energi baterai.</p>
</div>
<br>
 <p class="mr-3 font-semibold">2. Pilih Mode ECO.
</p>
<p>Gunakan mode ECO untuk mengoptimalkan penggunaan energi saat berkendara, terutama di jalan perkotaan yang padat.
<br>
<br>
<p class="mr-3 font-semibold">3. Kurangi Beban Berat</p>
<p>Hindari membawa beban yang berlebihan karena dapat membuat motor bekerja lebih keras dan menguras lebih banyak energi.
<br>
<br>
<p class="mr-3 font-semibold">4. Hindari Pengisian Daya Berlebihan</p>
<p>Jangan mengisi daya baterai terlalu lama karena dapat mengurangi usia baterai.</p>
<br>
<p class="mr-3 font-semibold">5. Perhatikan Tekanan Ban </p>
<p>Pastikan tekanan ban sesuai dengan rekomendasi pabrikan. Ban yang kurang angin bisa meningkatkan konsumsi energi.
</p>
<br>
<p class="mr-3 font-semibold">6. Lakukan Perawatan Berkala </p>
<p>Pastikan motor listrik kamu selalu dalam kondisi optimal dengan melakukan perawatan secara berkala.</p>
<br>
<p class="mr-3 font-semibold">7. Gunakan Aplikasi Navigasi  </p>
<p>Aplikasi navigasi bisa membantu kamu menemukan rute tercepat dan paling efisien, sehingga bisa menghemat energi.
</p>
<p class="mr-3 font-semibold">8. Hindari Aksesori Berlebihan</p>
<p>Hindari penggunaan aksesoris tambahan yang menguras daya, seperti lampu tambahan atau pemanas, karena ini dapat meningkatkan konsumsi energi.
</p>
<br>
<p>Dengan menerapkan tips-tips di atas, IMOTO OCTA GT bisa menjadi sahabat terbaik untuk mobilitas sehari-hari, tidak hanya ramah lingkungan tetapi juga sangat efisien. Rasakan kenyamanan berkendara dengan motor listrik tanpa polusi dan hemat energi bersama IMOTO!</p>
<br>
  `,
    related: ["1", "2", "3", "4", "5", "6", "7", "9"],
  },
  {
    id: "9",
    title: "IMOTO Indonesia Memukau Tech in Asia dengan Inovasi EV canggihnya",
    label: "Event",
    slug: "IMOTO-Indonesia-Memukau-Tech-in-Asia-dengan-Inovasi-EV-canggihnya",
    image: `${assetBaseUrl}/image/contents/TIA1.jpg`,
    description:
      "IMOTO Indonesia mencuri perhatian di Tech in Asia Conference 2024 dengan memamerkan kendaraan listrik andalan, VISION.ev, bersama deretan teknologi inovatif yang siap mengubah masa depan mobilitas di Indonesia.",
    content: `<div class="font-bold mb-5"><p>IMOTO Indonesia mencuri perhatian di Tech in Asia Conference 2024 dengan memamerkan kendaraan listrik andalan, VISION.ev, bersama deretan teknologi inovatif yang siap mengubah masa depan mobilitas di Indonesia.</p></div>
<br><p class="text-xl font-semibold">Memboyong teknologi cangggih</p>
<img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/TIA2.jpg" alt="gambar detail komponen motor">
<br><p>Di konferensi tersebut, Abhisek Yadav sebagai COO dan Doddy Lukito sebagai CIO IMOTO Indonesia tampil di panggung utama pada hari pertama untuk memaparkan visi mereka. Mereka menyoroti jajaran teknologi mengesankan yang ditampilkan di Tech in Asia Conference 2024, memikat perhatian seluruh peserta. Selama dua hari acara yang penuh semangat ini, IMOTO Indonesia berhasil memukau audiens dengan berbagai inovasi terdepan, di antaranya:</p><br>
<p class="mr-3 font-semibold">1. IMOTO Futech Frame:</p>   
<p>Platform modular dan skalabel yang menggabungkan desain super ringan dengan performa tinggi, menetapkan standar baru bagi kendaraan listrik masa depan.
</p>
</div>
<br>
 <p class="mr-3 font-semibold">2. IMOTO NEXT-GEN VCU:
</p>
<p>Unit kontrol kendaraan generasi terbaru yang memungkinkan pemantauan secara mudah dan integrasi mulus dengan berbagai platform, menghadirkan pengalaman berkendara yang lebih cerdas dan terhubung.

<br>
<br>
<p class="mr-3 font-semibold">3. Rapid Fast Charge Stations:</p>
<p>Dengan target jaringan 200 titik, stasiun ini mendukung IMOTO RAPID BATTERY untuk pengisian dari 20% ke 80% hanya dalam 30 menit, menjawab kebutuhan mobilitas cepat di era modern.
<br>
<br><p class="text-xl font-semibold">Visi Untuk Masa Depan</p><br/>
<img src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/contents/TIA3.jpg" alt="gambar detail komponen motor">
<br><p>IMOTO Indonesia didorong oleh ambisi besar untuk menjadi produsen utama kendaraan roda dua listrik di Tanah Air, menjadikan Indonesia sebagai pusat industri kendaraan listrik (EV). Dengan tekad kuat untuk mencapai 75% tingkat kandungan lokal, IMOTO tidak hanya fokus pada penciptaan inovasi kelas dunia, tetapi juga berupaya membuka lapangan kerja baru dan mengasah talenta lokal dalam sektor EV.</p><br>
<br><p>IMOTO mentargetkan masa depan mobilitas yang bersih, hijau, hemat biaya, dan efisien bagi masyarakat Indonesia sebuah langkah nyata menuju transportasi berkelanjutan yang mendukung kualitas hidup dan lingkungan.</p><br>
<br><p class="text-xl font-semibold">Dari indonesia, Untuk Dunia</p>
<br><p>IMOTO Indonesia hadir sebagai simbol inovasi yang membanggakan, dengan lantang menyatakan bahwa teknologi mutakhirnya dirancang dan dikembangkan di Indonesia untuk dunia. Inisiatif lokal ini bukan hanya mengangkat talenta anak bangsa, tetapi juga menegaskan potensi Indonesia sebagai pemimpin di pasar kendaraan listrik global.</p>

<br><p>Seiring meningkatnya kebutuhan akan transportasi berkelanjutan, IMOTO Indonesia siap menjadi pelopor dalam menggerakkan masa depan mobilitas di Asia Tenggara dan dunia. Gema antusiasme di Konferensi Tech in Asia menjadi titik awal dari perjalanan menginspirasi menuju masa depan yang lebih hijau dan ramah lingkungan.</p>
  `,
    related: ["1", "2", "3", "4", "5", "6", "7", "8"],
  },
];
