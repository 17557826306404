export default {
  // LANDING PAGE
  download: `<p class="text-white text-lg md:text-4xl">
      Power at your fingertips.
      <b>Find, Monitor, and Control</b> Your Ride with
      <b>IMOTO.ev</b>
    </p>`,
  available: "Available on:",

  // OCTA PAGE
  unleash: `<p class="text-white text-lg md:text-3xl">
              Unleash <b>Your Youthful</b> Spirit With
            </p>`,
  yourStyle: `<h2 class="text-white text-lg md:text-3xl leading-10 mt-4">
              Own Your Style, Electrify Your Life. <br />
              <b>Order Yours Now</b>
            </h2>`,

  // OCTA spesification
  spesification: "Spesification",
  battery: "Battery",
  tyres: "Tyres (F/R)",
  speed: "Max Speed",
  motor: "Motor Capacity",
  transmission: "Transmission Type",
  hub: "Hub Drive",
  cooling: "Cooling-down Method",

  //   OCTA Gallery
  title1: "Front Suspension",
  desc1: "Spring Rocker Arm Front Shock",
  title2: "Rear Suspension",
  desc2: "Hydraulic Spring Shock Absorber",
  title3: "Bracket Box",
  desc3: "Compact Carrier, Maximum Convenience",
  title4: "Button",
  desc4: "Designed for comfort and fit",
  title5: "All LED Headlamp",
  desc5: "Lighting distance 65M",
  title6: "Battery Pack",
  desc6: "4 hours charging time",

  // Ride in style
  rideIn: "Ride in Style Every Day!",
  descRidein:
    "OCTA GT offers duraility, Power strength and comfort for an enjoyable day-to-day ride",

  //   Be the spark
  spark: "BE THE SPARK OF",
  octageneration: "#OCTAGENERATION",
  embrace:
    "Embrace a lifestyle full of vibrant days and infinite fun. Suits the urban lifestyle and blends seamlessly with a sustainable living style.",
  price: "Price",
  price1: "1 Battery RP. 26.000.000",
  price2: "2 Battery RP. 36.000.000",
  Define: "DEFINE YOUR PERSONALITY",

  // Connect with App
  connect: "Connect With APP",
  descConnect:
    "Unleash your bike's full potential. Take your riding experience to the next level with Advanced features, ultimate control. IMOTO.EV is your co-pilot.",

  // Test Ride
  testride: "Book a Test Ride",
  descTestride:
    "Submit your details to find out about our booking benefits with limited-time offers exclusives*",
  noteTestride:
    "Please bring your driver's license along for doing the test drive.",
  tncTitle: "*Terms & conditions apply",
  tncTestride:
    "I agree that you and IMOTO Indonesia and/or their service providers may call me and/or send marketing information and/or information on events and promotions to me about your or their products and services through SMS, MMS, fax, mailers or email.",
  bookNowButton: "Book Now",

  // ABOUT PAGE
  ourStory: "OUR STORY",
  whoAreWe: "Who Are We?",
  storyDesc: `Imoto is an organisation run by Global Automotive enthusiast and professional who are passionate about Automotive and technology, who believe future of Indonesia Automotive Industry is EV and Imoto will continuously innovate to build Products and services which will make Carbon free mobility at a very low adoption cost. 
  <br> <br> With Racing Pedigree, imoto is building an Electric Bike FOR Indonesia, IN Indonesia and BY Indonesian where Riders can feel the adrenaline rushing the moment they sit on the bike and throttle the bike. Bikes will have imoto “FuTech” technology with no compromise on Performance which will change the way consumers have perceived EV.`,
  ourThing: "OUR THING",
  whyWeExist: "Why We Exist",
  thingDesc:
    "We in imoto are determined to change lives by providing Zero Emission and High Performance Electric Mobility. We are building technologies for rapid decarbonization of mobility.",
  ourMission: "OUR MISSION",
  weDoing: "What Are We Doing?",
  doingDesc:
    "imoto was established in 2021 as a full-fledged automotive OEM with R&D in Indonesia and India, focussing on Electrical mobility. We believe that by 2035 imoto will be a Zero Carbon company and our products will deliver what we envisage and commit today and team with racing pedigree is working with a clear motto of providing the best of the EV technology to the consumers, at the most affordable cost. imoto with its R&D center in Indonesia and India is working on :",
  doingList1: "imoto controller technology",
  doingList2: "Native EV Frame Design from Ground Zero.",
  doingList3: "Innovative Frame and Body Material.",
  ourTech: "OUR TECHNOLOGY",
  techProduct: "Our Technology Product",
  techDesc:
    "Since imoto’s establishment, imoto has released their own products, hardware and software. Those products are:",
  techList1:
    "is a service provider platform for smart electric mobility (MaaS) that offers electric motorcycle subscription services. The Imotoshare platform is very easy and safe to use, offering high performance, long battery life, and better riding comfort compared to conventional motorcycles, making it the best choice to accommodate your transportation needs.",
  techList2:
    "Imoto 1Cloud is a fully in-house developed, cloud-based EV fleet management ecosystem, designed to revolutionize the way you manage your electric vehicles. Paired with the powerful Imoto App, it delivers valuable big data insights that enrich your driving experience with enhanced interactivity and real-time information. Imoto 1Cloud is the key to unlocking the full potential of your electric fleet, offering smarter, and more efficient.",
  techList3:
    "Team imoto has developed a unique 'Rapid' Fast-Charging battery pack which can charge Battery from 20% to 80% in just 30 mins. This is the first of its kind in Indonesia developed inhouse by a young energetic team in just 2 years of hard and dedicated work. This will revolutionize the EV market in Indonesia and the acceptability of EV will increase rapidly. This allows Riders to experience cutting-edge Battery Technology built fully in Indonesia. So .. Charge Faster, Speed up and Ride Longer with us.",
};
