<template>
  <navbar-hide />

  <div class="snap-y snap-mandatory md:h-screen w-screen overflow-scroll">
    <div class="relative">
      <video
        ref="videoPlayer"
        class="w-full inset-0 md:h-screen snap-start object-cover"
        preload="none"
        muted
        playsinline
        autoplay
        @ended="replayVideo"
        controls
      >
        <source :src="`${assetBaseUrl}/video/about.mp4`" type="video/mp4" />
      </video>

      <div
        class="absolute inset-x-0 bottom-0 flex items-center justify-center z-50"
      >
        <ChevronDownIcon class="text-white size-8 fixed animate-bounce mb-8" />
      </div>
    </div>

    <!-- section 1 test -->
    <div
      class="snap-always snap-center bg-white bg-cover bg-center md:min-h-screen md:h-screen relative flex items-center justify-center overflow-hidden"
    >
      <div
        class="absolute top-0 left-0 w-[500px] h-[500px] bg-blue-500 rounded-full blur-3xl opacity-10 transform -translate-x-1/2 -translate-y-1/2"
      ></div>

      <div
        class="absolute bottom-0 right-0 w-[500px] h-[500px] bg-pink-500 rounded-full blur-3xl opacity-10 transform translate-x-1/2 translate-y-1/2"
      ></div>

      <div
        class="container mx-auto px-6 md:px-16 relative py-16 flex flex-col md:flex-row md:h-screen w-screen"
      >
        <!-- Bagian Kiri (Desktop) / Bagian atas (Mobile) -->
        <div
          class="flex items-center justify-center w-full h-3/5 md:h-full md:w-1/2"
        >
          <img
            rel="preload"
            class="w-full md:w-4/5 md:pt-20"
            :src="`${assetBaseUrl}/image/contents/about1.png`"
            alt="Product Image"
          />
        </div>
        <!-- Bagian Kanan (Desktop) / Bagian bawah (Mobile) -->
        <div
          class="flex text-black md:justify-center md:items-center w-full md:h-full md:w-1/2"
        >
          <div class="text-left md:text-left font-medium md-w:4/5">
            <p class="text-black mt-4 font-semibold text-lg">
              {{ $t("ourStory") }}
            </p>

            <h1 class="pt-4 text-3xl font-bold md:text-6xl">
              {{ $t("whoAreWe") }}
            </h1>
            <div class="text-black mt-4">
              <div v-html="$t('storyDesc')"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section 2 -->
    <div
      class="snap-always snap-center bg-white bg-cover bg-center min-h-screen h-screen relative flex items-center justify-center overflow-hidden"
    >
      <div
        class="absolute top-0 left-0 w-[500px] h-[500px] bg-blue-500 rounded-full blur-3xl opacity-10 transform -translate-x-1/2 -translate-y-1/2"
      ></div>

      <div
        class="absolute bottom-0 right-0 w-[500px] h-[500px] bg-pink-500 rounded-full blur-3xl opacity-10 transform translate-x-1/2 translate-y-1/2"
      ></div>

      <div
        class="container mx-auto px-6 md:px-16 relative py-16 flex flex-col md:flex-row-reverse h-screen w-screen"
      >
        <!-- Bagian Kiri (Desktop) / Bagian atas (Mobile) -->
        <div
          class="flex items-center justify-center w-full h-3/5 md:h-full md:w-1/2"
        >
          <img
            rel="preload"
            class="w-full md:w-4/5 md:pt-20"
            :src="`${assetBaseUrl}/image/contents/about2.png`"
            alt="Product Image"
          />
        </div>
        <!-- Bagian Kanan (Desktop) / Bagian bawah (Mobile) -->
        <div
          class="flex text-black md:justify-center md:items-center w-full md:h-full md:w-1/2"
        >
          <div class="text-left md:text-left font-medium md-w:4/5">
            <p class="text-black mt-4 text-lg font-semibold">
              {{ $t("ourThing") }}
            </p>

            <h1 class="pt-4 text-3xl font-bold md:text-6xl">
              {{ $t("whyWeExist") }}
            </h1>
            <p class="text-black mt-4">
              {{ $t("thingDesc") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- section 3 -->
    <div
      class="snap-always snap-center bg-white bg-cover bg-center md:min-h-screen md:h-screen relative flex items-center justify-center overflow-hidden"
    >
      <div
        class="absolute top-0 left-0 w-[500px] h-[500px] bg-blue-500 rounded-full blur-3xl opacity-10 transform -translate-x-1/2 -translate-y-1/2"
      ></div>

      <div
        class="absolute bottom-0 right-0 w-[500px] h-[500px] bg-pink-500 rounded-full blur-3xl opacity-10 transform translate-x-1/2 translate-y-1/2"
      ></div>

      <div
        class="container mx-auto px-6 md:px-16 relative py-16 flex flex-col md:flex-row md:h-screen w-screen"
      >
        <!-- Bagian Kiri (Desktop) / Bagian atas (Mobile) -->
        <div
          class="flex items-center justify-center w-full h-3/5 md:h-full md:w-1/2"
        >
          <img
            rel="preload"
            class="w-full md:w-4/5 md:pt-20"
            :src="`${assetBaseUrl}/image/contents/about3.png`"
            alt="Product Image"
          />
        </div>
        <!-- Bagian Kanan (Desktop) / Bagian bawah (Mobile) -->
        <div
          class="flex text-black md:justify-center md:items-center w-full md:h-full md:w-1/2"
        >
          <div class="text-left md:text-left font-medium md-w:4/5">
            <p class="text-black mt-4 font-semibold text-lg">
              {{ $t("ourMission") }}
            </p>

            <h1 class="pt-4 text-3xl font-bold md:text-6xl">
              {{ $t("weDoing") }}
            </h1>
            <p class="text-black mt-4">
              {{ $t("doingDesc") }}
            </p>
            <div class="pt-4 max-w-lg mx-auto">
              <!-- List 1 -->
              <div class="flex items-start space-x-3 mb-4">
                <i class="fas fa-check text-green-500 text-xl"></i>
                <p class="text-gray-800 text-lg">
                  {{ $t("doingList1") }}
                </p>
              </div>

              <!-- List 2 -->
              <div class="flex items-start space-x-3 mb-4">
                <i class="fas fa-check text-green-500 text-xl"></i>
                <p class="text-gray-800 text-lg">
                  {{ $t("doingList2") }}
                </p>
              </div>

              <!-- List 3 -->
              <div class="flex items-start space-x-3 mb-4">
                <i class="fas fa-check text-green-500 text-xl"></i>
                <p class="text-gray-800 text-lg">
                  {{ $t("doingList3") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section 4 -->
    <div
      class="snap-always snap-start bg-white bg-cover bg-center relative flex items-center justify-center overflow-hidden"
    >
      <div
        class="absolute top-0 left-0 w-[500px] h-[500px] bg-blue-500 rounded-full blur-3xl opacity-10 transform -translate-x-1/2 -translate-y-1/2"
      ></div>

      <div
        class="absolute bottom-0 right-0 w-[500px] h-[500px] bg-pink-500 rounded-full blur-3xl opacity-10 transform translate-x-1/2 translate-y-1/2"
      ></div>
      <div
        class="container mx-auto px-6 md:px-16 relative py-16 flex flex-col md:flex-row-reverse w-screen"
      >
        <!-- Bagian Kiri (Desktop) / Bagian atas (Mobile) -->
        <div
          class="flex items-center justify-center w-full h-3/5 md:h-full md:w-1/2"
        >
          <img
            rel="preload"
            class="w-full md:w-4/5 md:pt-20"
            :src="`${assetBaseUrl}/image/contents/about4.png`"
            alt="Product Image"
          />
        </div>
        <!-- Bagian Kanan (Desktop) / Bagian bawah (Mobile) -->
        <div
          class="flex text-black md:justify-center md:items-center w-full md:h-full md:w-1/2"
        >
          <div class="text-left md:text-left font-medium md-w:4/5">
            <p class="text-black mt-4 text-lg font-semibold">
              {{ $t("ourTech") }}
            </p>

            <h1 class="pt-4 text-3xl font-bold md:text-6xl">
              {{ $t("techProduct") }}
            </h1>
            <p class="text-black mt-4">
              {{ $t("techDesc") }}
            </p>

            <div class="pt-4 max-w-lg mx-auto">
              <!-- List 1 -->
              <div class="mb-6">
                <p class="text-gray-800 text-xl font-bold">1. Imotoshare</p>
                <p class="text-gray-600 pl-4 text-base mt-2">
                  {{ $t("techList1") }}
                </p>
              </div>

              <!-- List 2 -->
              <div class="mb-6">
                <p class="text-gray-800 text-xl font-bold">
                  2. imoto 1Cloud Ecosystem
                </p>
                <p class="text-gray-600 pl-4 text-base mt-2">
                  {{ $t("techList2") }}
                </p>
              </div>

              <!-- List 3 -->
              <div class="mb-6">
                <p class="text-gray-800 text-xl font-bold">
                  3. imoto "Rapid" fast charge battery
                </p>
                <p class="text-gray-600 pl-4 text-base mt-2">
                  {{ $t("techList3") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer class="snap-normal snap-center bg-center relative" />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import { Vue3Lottie } from "vue3-lottie";
import { ChevronDownIcon } from "@heroicons/vue/24/solid";
import NavbarHide from "../components/NavbarHide.vue";
import FloatingCarbon from "../components/FloatingCarbon.vue";

export default {
  name: "VideoPlayer",
  components: {
    Footer,
    ChevronDownIcon,
    Vue3Lottie,
    NavbarHide,
    FloatingCarbon,
  },
  data() {
    return {
      assetBaseUrl: import.meta.env.VITE_STATIC_ASSET_BASE_URL,
    };
  },
  mounted() {
    this.$refs.videoPlayer.play(); // Autoplay the video when component is mounted
  },
  methods: {
    replayVideo() {
      this.$refs.videoPlayer.play(); // Replay the video
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
